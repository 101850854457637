import { Controller } from "@hotwired/stimulus";
import ahoy from "ahoy.js";

export default class extends Controller {
  static values = {
    message: String,
    title: String,
    eventName: {
      type: String,
      default: "",
    },
  };
  initialize() {
    this.element.addEventListener("click", (ev) => {
      ev.preventDefault();
      this.shareLink();
    });
  }
  shareLink() {
    const url = this.element.getAttribute("href");

    const data = {
      title: this.titleValue,
      url,
    };

    if (this.eventNameValue) {
      ahoy.track(this.eventNameValue);
    }

    if (navigator.canShare && navigator.canShare(data)) {
      navigator.share(data);
    } else {
      navigator.clipboard.writeText(url);

      const event = new CustomEvent("toast:show", {
        detail: {
          message: "¡Enlace copiado!",
          messageType: "success",
        },
      });

      window.dispatchEvent(event);
    }
  }
}
